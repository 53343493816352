import React from 'react';
import { useRouter } from 'next/router';
import styles from './partners.module.sass';
import { Icon } from '../Icon';
import { Text } from '../Text';
import { seaPartners } from './arraysPartners';
import { ReworkUrl } from '../../utils/reworkUrl';

export const Partners = ({ title, desc, vendors, isMobile }) => {
  const router = useRouter();
  const { type } = router.query;
  const isSea =
    type === 'sea' ||
    router.asPath.startsWith('/sea_cruise') ||
    router.asPath.startsWith('/sea');
  const partners = isSea ? seaPartners : vendors;

  return (
    <section>
      <div className={styles.partnesHeader}>
        <Text variant="heading-1" component="h2">
          {title}
        </Text>
      </div>
      {desc && <p className={styles.desc}>{desc}</p>}
      {!isSea ? (
        <div className={styles.partnersContent}>
          {partners?.map((partner, key) => (
            <a
              className={styles.partnersLogo}
              key={key}
              href={partner.cruises_link}
              rel="noreferrer"
            >
              <img
                src={`${ReworkUrl(partner.logo)}`}
                alt={`Логотип ${partner.caption}`}
                loading={'lazy'}
                width={150}
                height={150}
              />
            </a>
          ))}
        </div>
      ) : (
        <div className={styles.partnersContent}>
          {partners?.map((partner, key) => (
            <div>
              <a
                className={styles.partnersLogo}
                key={key}
                href={ReworkUrl(partner.href)}
                rel="noreferrer"
              >
                <Icon icon={partner.logoPartners} title="Логотип партнёра" />
              </a>
            </div>
          ))}
        </div>
      )}
    </section>
  );
};
