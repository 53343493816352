import React from 'react';
// icon partners sea
// import Astoria from '../../public/icons/partners/sea/Astoria.svg';
// import Celestial from '../../public/icons/partners/sea/Celestial.svg';
// import Costa from '../../public/icons/partners/sea/Costa.svg';
// import MSC from '../../public/icons/partners/sea/MSC.svg';
// import RoyalCaribbean from '../../public/icons/partners/sea/RoyalCaribbean.svg';
import { Icon } from '../Icon';
import { IconSVG } from '../SpriteSVG';

export const seaPartners = [
  {
    logoPartners: (
      // нужна другая svg Астории
      <Icon
        icon={
          <IconSVG
            name={'seaPartners/Astoria'}
            width={150}
            height={150}
            title="Логотип партнёра"
          />
        }
      />
    ),
  },
  {
    logoPartners: (
      <Icon
        icon={
          <IconSVG
            name={'seaPartners/Celestial'}
            width={150}
            height={150}
            title="Логотип партнёра"
          />
        }
        title="Логотип партнёра"
      />
    ),
  },
  {
    logoPartners: (
      <Icon
        icon={
          <IconSVG
            name={'seaPartners/Costa'}
            width={150}
            height={150}
            title="Логотип партнёра"
          />
        }
        title="Логотип партнёра"
      />
    ),
  },
  {
    logoPartners: (
      <Icon
        icon={
          <IconSVG
            name={'seaPartners/MSC'}
            width={150}
            height={150}
            title="Логотип партнёра"
          />
        }
        title="Логотип партнёра"
      />
    ),
  },
  {
    logoPartners: (
      <Icon
        icon={
          <IconSVG
            name={'seaPartners/RoyalCaribbean'}
            width={150}
            height={150}
          />
        }
      />
    ),
  },
];
